import { Flex, Spacing, Txt } from '@florencecard/components';
import React from 'react';
import Footer from '~/components/Footer';
import { NextPage } from '~/core';

const NotFoundPage: NextPage = () => {
  return (
    <>
      <main>
        <Flex.Center flexDirection="column">
          <Spacing size={120} />
          <Txt as="h1" fontSize={36}>
            존재하지 않는 페이지 입니다.
          </Txt>
          <Spacing size={400} />
        </Flex.Center>
      </main>
      <Footer />
    </>
  );
};

NotFoundPage.metadata = {
  title: '페이지를 찾을 수 없습니다.',
};

export default NotFoundPage;
